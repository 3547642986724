export const Friend = (props) => {
  return (
    <div id='friend'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>合作伙伴</h2>
          
         
        </div>
        <div className="row">
        	{props.data
        		? props.data.map((item, index) => (
        		<div className='col-xs-4' key={index}>
        		<img src={item} className="friend-img" alt="Project Title" />{" "}
        		</div>
        	  
        	)): 'loading'}
        	
        	
        </div>
      </div>
    </div>
  )
}
