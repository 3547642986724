import BannerAnim from 'rc-banner-anim';
import TweenOne from 'rc-tween-one';
// import Texty from 'rc-texty';
import 'rc-banner-anim/assets/index.css';
// import 'rc-texty/assets/index.css';
// import SmoothScroll from 'smooth-scroll'
const { Element, Arrow, Thumb } = BannerAnim;

const BgElement = Element.BgElement;

export const Services = (props) => {
	const state = {
		intShow: 0,
		prevEnter: false,
		nextEnter: false,
		thumbEnter: false,
	};
	const bannerList = [
		{
			"img": "img/services/gscldtczxt.jpg",
			"name": "高速车辆动态称重系统",
			"principle": "每车道埋设两条或以上压电薄膜或石英压力传感器，传感器间距2.5米，在传感器前方埋设长方形地感线圈，车辆通过压力传感器时，每个或每组车轮施加的压力与传感器输出的电荷信号相对称，车轮压过传感器时输出的信号由采集单元电路转换成数字量电压，电压通过MCU计算可以检测出车的重量、轴数、轴距、速度、车型、车长等数据。其中车长数据由车辆金属底盘激活感应线圈的时长得出，同时感应线圈也用于做车辆分割。动态称重仪表由两个单元组成，采集单元采用嵌入式低功耗高性能的ARM芯片，主要负责压力传感器的信号采集与信号数据处理运算，并输出数据至数据处理单元。数据处理单元采用低功耗Intel Bay trail SOC架构，主要负责数据存储、卡口相机接入、可变情报板控制、数据接口服务等。压电传感器埋设于路面，与路面融为一体，在车辆经过传感器路面时，受机械冲击或振动时（轮胎压力）使传感器受力并产生电荷，称重仪表采集受力产生的时长、信号积分运算，最后得出重量等数据。",
			"Features": "公路交通结构安全自动化监测,路桥荷载监测,超载超限高速预检,不停车超限管理（非现场执法）,交通荷载（轴载）监测,桥梁荷载分布检测,冶超站动态初检,交通流量数据采集"
		},
		{
			"img": "img/services/znxsgjj.png",
			"name": "智能弦式钢筋计",
			"principle": "智能弦式钢筋计，主要由线圈、钢弦和受力钢体组成。当发生应力时，振弦式钢筋计的受力钢体产生应变并传递给钢弦，使钢弦受力发生变化，从而改变钢弦的固有频率，测量仪表输出脉冲信号通 过线圈激振钢弦并检测出线圈所感应信号的频率，经换算得到被测结构物的荷载力。内置温度传感器置同时监测安装位置的温度，便于进行实时温度补偿，提高传感器在不同温度条件下监测数据的准确性和可靠性。",
			"Features": "主要用来监测混凝土或其它结构中钢筋及锚杆的应力，安装在混凝土土受力钢筋上监测钢筋应力的仪器，埋设于各类建筑基础、桩、地下连续墙、隧道衬砌、桥梁、边坡、码头船坞、闸门等混凝土工程及基坑等结构中。"
		},
		{
			"img": "img/services/nmybj.png",
			"name": "智能弦式表面/内埋应变计",
			"principle": "主要由夹弦器、钢弦、线圈以及安装头组成，其中钢弦通过夹弦器与两端安装头相连，钢弦上被预加一定张力固定于传感器内。根据经典弦原理，当被测结构物由于外力作用产生形变，并通过应变计两端安装头传递至钢弦使其应变量发生变化，从而导致钢弦固有频率亦随之改变。通过测量钢弦频率的变化，即可得知被测结构物的应变变化量。并且内置半导体温度传感器，取钢弦和被测结构材料的线胀系数进行温度修正",
			"Features": "智能弦式内埋应变计，主要应用于公路、铁路、地铁、隧道大坝、基桩等混凝土结构内部的应变测量。捆扎与钢筋上或埋设在混凝土结构内，用于结构物的应变、应力测量。智能弦式表面式应变计主要应用于>桥梁、公路、铁路、地铁、隧道、管线、桩基等结构的应变监测，可焊接在钢结构表面或螺栓固定在各种结构的表面进行长期自动化监测和定期检测。"
		},
		{
			"img": "img/services/znxstylj.png",
			"name": "智能弦式土压力计",
			"principle": "主要由承压膜、夹弦器、钢弦以及线圈组成，其中钢弦被预加一定张力通过夹弦器平行固定于承压膜上。当外界土体压力作用于土压力盒承压膜上使其发生微小变形，从而导致固定于承压膜上的钢弦张力发生变化，钢弦固有频率亦随之改变。通过测量钢弦固有频率的变化，即可得被测土体应力。",
			"Features": "用于测量土石坝、土堤、边坡、路基、挡土墙、隧道等结构物内部土体的压应力的测量。本产品适于长期自动化监测，主要部件均用特殊钢材制造，可在恶劣环境使用。其性能优异，具有良好的精度和灵敏度、卓越的 防水性能、耐腐蚀性和长期稳定性等，在完善电缆保护措施后，可直接埋设在对仪器要求较高的碾压土中。"
		},
		{
			"img": "img/services/msj.png",
			"name": "锚索计",
			"principle": "锚索计是通过采用在中空的承压简体上安装高稳定性、高灵敏度的振弦式传感器，然后测量承压筒体上的应变变化推出承压筒体上所承受的荷载，当被测载荷作用在锚索计上，将引起弹性圆筒的变形并传递给钢弦，转变成振弦应力的变化，从而改变振弦的振动频率。电磁线圈激振钢弦并 测量其振动频率，频率信号经电缆传输至振弦式读数仪上，即可测读出频率值.从而计算出作用在锚索测力计的载荷值。振弦式传感器的频率会受温度影响，因此在测试振弦频率时应同步测 试温度，以便进行测试数值的修正。",
			"Features": "可测量各种锚杆、锚索、岩石螺栓、支柱、隧道与地下洞室中的支撑以及大型预应力钢筋混凝土结构（桥梁和大坝等）中的荷载和预应力的损失情况。"
		},
		{
			"img": "img/services/kxsyj.png",
			"name": "空隙水压计",
			"principle": "孔隙水压计主要由透水石、承压膜、钢弦、夹弦器以及线圈组成，其中承压膜与钢弦相连，钢弦上被预加一定张力固定于传感器内。当外界水压通过透水石作用于孔隙水压计承压膜上使其发生微小变形，从而导致与承压膜相连接的钢弦张力发生变化，其固有频率亦随之改变。钢弦固有频率的平方与膜片上水压力成正比关系，通过测量钢弦频率的变化，即可得知被测渗水压力大小，0.01MPa相当于1米高水柱压强，故通过孔隙水压计从而可知水位。",
			"Features": "孔隙水压计是一种用于测量孔隙水压力或渗透压力的传感器，广泛应用于大坝在线监测、尾矿库监测、隧道监测、路基、边坡监测等工程中地基深层水压力的测量，从预钻孔压入至设计层面待测点。"
		},
		// {
		//     "img": "",
		//     "name": "智能动态振弦采集仪",
		//     "principle": "通过采集仪内的激振电路驱动传感器的线圈产生磁场，从而触发应传感器内的钢弦使其产生振动。钢弦振动后会按照一定的频率切割感应线圈产生的磁场，并在感应线圈中生成相同频率的感应电势，通过采集仪内的拾取电路拾取到这组信号，并经由滤波电路、信号放大电路、整形电路传输给单片机，由单片机对信号进行分析处理，得出传感器的输出频率，经采集仪和软件平台的计算得出对应传感器的目标值。",
		// 	"Features":"智能动态振弦采集仪是一种岩土原位监测仪器，适用于振弦式传感器输出的频率信号计算及温度采集。可用于大坝、桥梁、堤坝、隧道、路基、高边坡等工程的应力、应变、变形、渗压等物理量监测和安全稳定分析。智能动态振弦采集仪计算集成化程度高、抗干扰性能强，通道数根据接入传感器数量灵活集成，采用通用标准RS485接口及接口防雷保护等设计使采集仪可在环境恶劣的工况条件中安全稳定的运行。"
		// },
		{
			"img": "img/services/GNSS.png",
			"name": "GNSS位移监测系统",
			"principle": "GNSS基本原理是测量出已知位置的卫星到用户接收机之间的距离，然后综合多颗卫星的数据就可知道接收机的具体位置。本产品采用高性能高精度SoC 芯片推出的全新一代北斗（全系统）远程位移测量系统，支持全系统多频点高精度定位，信号支持中国北斗、 美国、俄罗斯格洛纳斯、 欧洲伽利略等卫星信号。由一个或多个定位仪组成的系统，布置在工程项目的监测点上，利用这些信号，通过软件解算等工作，测量其水平位移和垂直位移。该系统是一种全天候、无人值守的自动化位置测量系统。",
			"Features": "GNSS位移监测系统广泛应用于边坡、高铁、桥梁、建筑、矿山、大坝等工程领域的结构表面位移变化的自动化监测。"
		},

		{
			"img": "img/services/gdndy.png",
			"name": "光电挠度仪",
			"principle": "将靶标通过数字图像位移传感器的长焦光学系统成像在摄像头的光敏面上，基于嵌入式处理平台的图像处理识别和高精度定位技术，确定像点的精确位置，最后由物像关系确定靶目标的空间位置。通过对不同时刻靶目标的空间位置比较分析，确定靶目标的变形情况，进而获得被测结构的变形参数。",
			"Features": "光电挠度仪是一款新型远距离非接触式位移测量传感器。产品主要应用于桥梁挠度长期自动化监测，具有自适应测量距离、测量范围大、测量精度高、体积小、集成度高、安装施工简便等特点。"
		},
		{
			"img": "img/services/jlszy.png",
			"name": "精力水准仪",
			"principle": "静力水准仪系统是用来测量相对高差变化的一种高精度测量仪器。基于连通管原理的工程应用，由多个静力水准仪通过一根充满液体的PU管连接在一起，最后连接到一个储液罐上。将储液罐及其附近的静力水准仪视作基点，基点必须安装在垂直位移相对稳定或者可以通过其他人工手段测量确定的位置，接下来就可以通过查看测点静力水准仪的压力变化直接测得该点的相对沉降。",
			"Features": "精力水准仪适用于岩土、结构安全工程长期的多点间的垂直位移监测，主要应用在大桥、隧道、桥梁、地铁、大坝、基坑、大型储罐等垂直位移监测。"
		},
		{
			"img": "img/services/hsxcy.png",
			"name": "盒式测斜仪",
			"principle": "盒式测斜仪通过测量被测点偏移角度值，利用三角函数换算得到该点位置的位移大小。盒式固定测斜仪测量角度核心部件为一个基于MEMS技术开发生产的高精度双轴倾角传感器，器件内部包含了硅敏感微电容传感器以及ASIC集成电路。通过内部倾斜传感器测量地球的重力加速度在X、Y轴上分量来对倾角进行测量，倾斜传感器所测量到的重力加速度分量等于倾斜角度的正弦(sin)×1g，通过逆运算就能得到角度数据。如果所测量到的重力加速度分量为0g，那么倾斜角就为0°，也可通过安装高度，计算得出偏移位移量",
			"Features": "主要用于桥梁（桥塔、高墩等）、基坑（基坑周围建筑物、高耸结构物）、电塔、石油机械（磕头机、高原机）等结构物水平位移或倾角的长期自动化监测。"
		},
		{
			"img": "img/services/dlsgdjcy.png",
			"name": "导轮式固定测斜仪",
			"principle": "导轮式固定测斜仪的工作原理是测量测斜管轴线与铅垂线之间的夹角变化，从而计算被测结构在不同深度的水平位移。长期监测过程中，套管从初始 位罝偏移至新的位罝，通过比较初始测量数据与当前实时测量数据得出位移的 速率，发生的深度及大小。",
			"Features": "主要监测边坡、尾矿库监测、堤坝、公路、防渗墙等结构的倾斜、水平位移或沉降变形、水平位移及倾角。配合自动化数据采集设备，可以自动、连续地监测，安装多个传感器可以获得沿测斜管轴向的挠度变形剖面图。"
		},
		{
			"img": "img/services/cfy.png",
			"name": "测缝计",
			"principle": "传感器由拉杆、铁芯、一组初级线圈、两组次级线圈、内管、外管等组成。当铁芯在线圈内移动时，两次级线圈产生差动电压。此两组电压经检波、相减、滤波、调零、放大等处理，输出一个与铁芯移动量相对应的信号。通过拟合曲线计算，输出位移值。",
			"Features": "主要用于铁路地铁在线监测、公路、桥梁结构健康监测及民用建筑、隧道结构安全监测、水电水利工程监测等。可测量混凝土、岩石、边坡等结构物裂缝或伸缩缝的开合度，也适用于各种结构的位移测量，适应长期监测和自动化定期测量。"
		},
		{
			"img": "img/services/jgwycgq.png",
			"name": "激光位移传感器",
			"principle": "激光位移传感器是一款利用激光相位法测量传感器与被测物之间的距离的新型传感器，具有测量速度快、测量精度高、产品体积小、使用方便等特点。",
			"Features": "主要应用于隧道拱顶沉降、隧道收敛、桥墩沉降、激光测距、模板沉降等特定位移监测场景。"
		},
		{
			"img": "img/services/gxgsybj.png",
			"name": "光纤光栅应变计",
			"principle": "光纤光栅应变传感器基于一种光纤光栅应变增/减灵敏度的封装机制，采用独特封装工艺将光纤光栅温度传感器与应变传感器巧妙的结合在一根传感器封装体内，有效的消除了温度场不均匀造成的温度补偿误差点，既可以直接埋入结构中也可以通过辅助构件构成夹持式传感器。",
			"Features": "海洋工程、土木工程中的钢结构表面长期应变测量、土木水利工程中的混凝土结构内部及表面长期应变测量、航天航空工程中的大体积复合材料内部长期应变测量。"
		},
		{
			"img": "img/services/gxgswdj.png",
			"name": "光栅光纤温度计",
			"principle": "采用双端出缆的结构，在一根光纤上可以串联多个传感器，形成准分布式的温度测量。内部敏感元件光纤光栅被封装在不锈钢内，耐压、抗拉，不受电磁干扰、抗雷击 、抗腐蚀、抗污染，可在易燃、易爆等恶劣条件下应用。安装方式采用机械固定，可贴敷、埋入或侵入被测物体中。",
			"Features": "桥梁、大坝等土木工程结构对温度的分布式精准测量；油罐、隧道、地下空间等火灾预警的温度监测； 工业生产与控制领域的高精度测温。"
		},
		{
			"img": "img/services/gxgscfj.png",
			"name": "光纤光栅测缝计",
			"principle": "传感器采用探杆安装方式，无源探测、温度补偿结构、灵敏度高，刚性连接，不受电磁干扰、抗雷击，耐腐蚀，适用于易燃易爆环境。光纤传输距离远，易于光纤组网。可以精确、快速测定桥梁、隧道、大坝、地下工程、边坡的位移变形。",
			"Features": "应用于桥梁、隧道、大坝、地下工程等伸缩位移、地面沉降、边坡滑移、山体滑坡位移等。"
		},
		// {
		//     "img": "",
		//     "name": "加速度传感器",
		//     "principle": "加速度计通过在内部嵌入一款MEMS芯片，该芯片能够根据不同的振动加速度值输出对应的电压值，且成线性关系，正是应用于该原理，可利用振动采集仪对输出的电压信号进行采集，再将电压转化得到对应加速度值，从而实现对振动加速度信号的测量。其具有体积小、重量轻、低频特性好、分辨率高、动态范围大、使用方便等特点。",
		// 	"Features":"适用于桥梁振动监测、拉索索力监测、爆破振动监测和电机振动监测等领域。"
		// },
		{
			"img": "img/services/ctlcgq.png",
			"name": "磁通量传感器",
			"principle": "磁通量传感器是基于铁磁性材料的磁弹效应原理制成，即当铁磁性材料承受的外界机械荷载发生变化时，其内部的磁化强度（磁导率）会发生变化。通过测量铁磁性材料制成的构件的磁导率变化，来确定构件的内力。将磁通量传感器穿心套在导磁材料构件上进行测量时，初级线圈内通入脉冲电流，此时会在构件的纵向上产生脉冲磁场，构件被磁化。由于电磁感应，次级线圈中将会产生感应电压。当构件受力时，其磁导率会发生变化，导致次级线圈上感应电压也随之变化，通过公式就可计算出此时构件受力的大小。",
			"Features": "可用于斜拉桥拉索、悬索桥或拱桥吊杆、预应力混凝土结构内的预应力钢绞线、张拉结构中张拉构件等金属缆索索力大小测量。既适用于施工期桥梁拉索或吊杆构件的索力测量，也可用于运营期桥梁拉索或吊杆构件的索力测量。"
		},
		{
			"img": "img/services/ctlcjy.png",
			"name": "磁通量采集仪",
			"principle": "磁通量采集仪脉冲电流发生器向磁通量传感器（初级线圈）发送脉冲电流，使被测构件被磁化，磁化后的构件会在纵向上产生脉冲磁场。由于电磁感应作用，传感器次级线圈中将会产生感应电压。采集仪拾取电路将会拾取这组电压信号，并对其和时间积分得到积分电压。",
			"Features": "磁通量采集仪是配套磁通量传感器使用的，可直接采集得 到传感器的安装点的温度及传感器感应构件所得的积分电压，并把采集结果传输给上位机软件计算得出此时构件内力大小。磁通量采集仪采用标准的RS458通信接口，可同其他RS485接口设备联网组成实时在线监测系统。"
		},
		{
			"img": "img/services/kghjjcz.png",
			"name": "空气环境监测站",
			"principle": "空气环境监测站，是一套集成颗粒物在线检测、气象参数在线检测为一体的新型在线监测终端。主要由扬尘监测单元、噪声监测单元、风速风向监测单元、温湿度监测单元、LED屏显示单元和中控单元等组成，可根据用户需求，灵活组成。实现环境参数的监测、展示、数据上传、数据对接等。",
			"Features": "主要应用于建筑扬尘、砂石厂、堆煤场、秸秆焚烧等无组织烟尘污染源排放的监控，居民区、商业区、道路交通、施工区域的环境空气质量在线实时监控。"
		},
		{
			"img": "img/services/trwsdcgq.png",
			"name": "土壤温湿度传感器",
			"principle": "土壤水份部分基于时域反射原理，具有高精度、高灵敏度的特点。通过测量土壤的介电常数，能直接稳定地反映各种土壤的真实水份含量。传感器产生的高频电磁波沿传输线进行传播，传输线的终端和周边物质的不连续会使传输中的部分能量通过传输线反射回来，这就像一束光沿着一个管道传播，在管的终端被镜子反射回来。通过测量电磁脉冲沿着传输线所需要的时间差即可测出土壤的介电常数，从而测出土壤的含水量。",
			"Features": "主要应用于滑坡、路基、农业、基坑、库区、实验室等应用领域。通常将测得的土壤含水量和温度数据，与其它变形数据（如沉降量、位移量等）一起进行综合性评估分析。"
		},
		{
			"img": "img/services/jgwdcgq.png",
			"name": "结构温度传感器",
			"principle": "利用铂热电阻特性，阻值跟温度的变化成正比，阻值会随着温度上升而成匀速增长的。铂电阻主要是解决常规方式表面测温不准确，采用稳定可靠的铂电阻丝制造的贴片铂电阻取代薄膜元件，具有表面测温准确、稳定等特点，精度也较半导体芯片原理的温度计高。",
			"Features": "结构物表面温度测量、桥梁混凝土结构内部温度测量、模型试验温度测量等等各种环境下工程温度监测领域。"
		},
		{
			"img": "img/services/hjwsdcgq.png",
			"name": "环境温湿度传感器",
			"principle": "温度传感器，是利用物质各种物理性质随温度变化的规律把温度转换为可用输出信号。温度传感器热电阻是中低温区最常用的一种温度检测器。它的主要特点是测量精度高，性能稳定。温度传感器热电阻测温是基于金属导体的电阻值随温度的增加而增加这一特性来进行温度测量的。湿度传感器，湿敏电阻的特点是在基片上覆盖一层用感湿材料制成的膜，当空气中的水蒸气吸附在感湿膜上时，元件的电阻率和电阻值都发生变化，利用这一特性即可测量湿度。",
			"Features": "主要应用在化工、能源、交通、铁路、水工建筑物等行业中的温湿度测量。具有高精度、高稳定性，高可靠性，防潮、绝缘及优越的防水性能，信号稳定。"
		},
		{
			"img": "img/services/fsfxcgq.png",
			"name": "风速风向传感器",
			"principle": "利用超声波时差法来实现风速的测量。声音在空气中的传播速度，会和风向上的气流速度叠加。若超声波的传播方向与风向相同，它的速度会加快；反之，若超声波的传播方向若与风向相反，它的速度会变慢。因此，在固定的检测条件下，超声波在空气中传播的速度可以和风速函数对应。通过计算即可得到精确的风速和风向。由于声波在空气中传播时，它的速度受温度的影响很大；风速仪检测两个通道上的两个相反方向，因此温度对声波速度产生的影响可以忽略不计。",
			"Features": "可广泛应用于环保、气象、农业、林业、水利、建筑、科研及教学等领域的风速风向监测。"
		},
		{
			"img": "img/services/ylj.png",
			"name": "雨量计",
			"principle": "雨量计采用高精度容栅位移传感器，采用上下电动阀控制进水和排水，使得雨量计在记录降水过程中雨量不流失，从而保证了计量过程的准确性，适合长期监测。",
			"Features": "主要用于边坡监测、尾矿库监测、水利工程、丛林及各种环境监测场合。"
		}
	]
	const thumbChildren = bannerList.map((item, i) =>
		<span key={i}><i style={{ backgroundImage: `url(${item.img})` }} /></span>

	);

	
	return (

		<div id='services' className='text-center'>
			<div className='container  page-scroll' id="container">
				<div className='section-title' >

					<BannerAnim prefixCls="banner-user component-banner-wrap" 
					autoPlay autoPlaySpeed={8000} type="across" arrow={false} delay={300}
					
					>
						{bannerList.map((item, index) => (
							<Element prefixCls="banner-user-elem" key={`${index}`} id={'ser'+index}>
								<BgElement key="bg" className="bg" style={{ backgroundImage: "url(" + item.img + ")" }}
								/>
								<h2>{item.name}</h2>
								<div className="content-card">
									<TweenOne className="box-container" animation={{ y: 30, opacity: 0, type: "from" }}>
										<img src={item.img} alt="" class="showPhoto"></img>
									</TweenOne>
									<div>
										<TweenOne className="title box-container" animation={{ y: 30, opacity: 0, type: "from" }}>
											<div class="min-title">产品原理</div>
											<text>{item.principle}</text>
										</TweenOne>
										<TweenOne
											className="box-container"
											animation={{ y: 30, opacity: 0, type: "from", delay: 100 }}
										>
											<div class="min-title">应用场景</div>
											<text>{item.Features}</text>
										</TweenOne>
									</div>
								</div>



							</Element>
						))}
						<Thumb prefixCls="user-thumb" key="thumb" component={TweenOne}
							animation={{ bottom: state.thumbEnter ? 0 : 0 }}
						>
							{thumbChildren}

						</Thumb>
					</BannerAnim>
				</div>

			</div>


		</div>



	)
}
