// import ParticlesBg from "particles-bg";
import React, {Component} from 'react';
import {render} from 'react-dom';
import ReactSwiper from 'reactjs-swiper';
// import '@/public/css/swiper.css'; // 自定义 css


export const Header = (props) => {
	const items = [{
	    image: 'img/bg1.jpg',
	    title: '图片1',
	   
	  }, {
	    image: 'img/bg2.jpg',
	    title: '图片2',
	  }];
	
	  const swiperOptions = {
	    preloadImages: true,
	    autoplay: 4000,
	    autoplayDisableOnInteraction: false
	  };
  return (
    <header id='header'>
      <div className='intro'>
		<ReactSwiper swiperOptions={swiperOptions} showPagination items={items}
                        className="swiper-example" />
        <div className='overlay'>
          <div className='container'>
			
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <h1>
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                <a
                  href='#features'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  查看更多
                </a>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
// render(
//   <ReactSwiperExample />, document.getElementById('layout')
// );