
export const Contact = (props) => {
  
  return (
    <div>
      <div id='contact'>
		
        <div className='container'>
          <h3>联系我们</h3>
		  <div className='row'>
			<div className='col-xs-6  contact-info'>
			  <div className='contact-item'>
			   
			    <p>
			      <span>
			        <i className='fa fa-map-marker'></i> 地址
			      </span>
			      {props.data ? props.data.address : 'loading'}
				  <p>
				  {' '}
				    {props.data ? props.data.address2 : 'loading'}
				  </p>
			    </p>
			  </div>
			  <div className='contact-item'>
			    <p>
			      <span>
			        <i className='fa fa-envelope-o'></i>联系人
			      </span>{' '}
			     {props.data ? props.data.person1 : 'loading'}
					<p>
					{' '}
					  {props.data ? props.data.person2 : 'loading'}
					</p>
				 
			    </p>
			  </div>
			  
			</div>
			
			<div className='col-xs-6 contact-info'>
			  <div className='contact-item'>
			   
			    <p>
			      <span>
			        <i className='fa fa-paper-plane'></i> 网址
			      </span>
			      {props.data ? props.data.webUrl : 'loading'}
			    </p>
			  </div>
			  <div className='contact-item'>
			    <p>
			      <span>
			        <i className='fa fa-tablet'></i>小程序
			      </span>
				  <img src='img/erweima.jpg' className='erweima'></img>
			    </p>
			  </div>
			  
			</div>
		  </div>
         
		  
        </div>
      </div>
    </div>
  )
}
