import { OverPack } from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';


export const Features = (props) => {

	return (
		<div id='features' className='text-center'>
			<div className='container'>
				<div className='col-md-10 col-md-offset-1 section-title'>
					<h2>软件产品</h2>
				</div>
				<div>


					<OverPack playScale={0.3}>

						<QueueAnim >
							<div className="code-box-shape queue-anim-demo" key="a">
								<div className='row margin-bottom-50 ' >
									<QueueAnim duration={2000} className="queue-simple" type="left">
										<img key="web1-img" className='col-xs-5' src='img/web1.png'></img>
									</QueueAnim>
									<div className='col-xs-1'></div>
									<div className='col-xs-6'>
										<QueueAnim duration={2000} className="queue-simple" type="right">
											<h3 key="web1-title" className="margin-bottom-30">智云物联网标准云平台</h3>
											<p key="web1-p">
												智云物联网标准云平台，采用C/S与B/S结合的架构方式，主要集成了
												终端设备数据采集、传输、存储、分析、安全评定及预警、文件报告存储
												等功能。对结构的监测信息进行实时、分级、多方式预警和监测报告信息
												发布。云平台支持https安全认证，数据安全可靠。根据监测行业的特性，
												进行针对性的研发来满足用户需求，并申请了桥梁健康监测管理系统、边坡
												地灾自动化监控系统、隧道自动化监测管理系统、基础建设大型结构物信息
												化监测预警系统等多个软件著作权。
											</p>
										</QueueAnim>



									</div>



								</div>
							</div>
							<div className="code-box-shape queue-anim-demo" key="b">
								<div className='row margin-bottom-50'>
									<div className='col-xs-6'>
										<QueueAnim duration={2000} className="queue-simple" type="left">
											<h3 key="web2-title" className="margin-bottom-30">物联网定制化平台</h3>
											<p key="web2-p">以智云物联网标准云平台为基础，可根据用户需求，定制大屏展示界面
												、Web登陆页面、传感器设备接入、报表定制、模型计算、安全评估等模块
												，并做本地化或私有云部署。
											</p>
										</QueueAnim>

									</div>
									<div className='col-xs-1'></div>
									<QueueAnim duration={2000} className="queue-simple" type="right">
										<img key="web2-img" className='col-xs-5' src='img/web2.png'></img>
									</QueueAnim>


								</div>
							</div>
							<div className="code-box-shape queue-anim-demo" key="c">
								<div className='row margin-bottom-50'>
									<QueueAnim duration={2000} className="queue-simple" type="left">
										<img key="phone-img" className='col-xs-5' src='img/web3.png'></img>
									</QueueAnim>

									<div className='col-xs-1'></div>
									<div className='col-xs-6'>
										<QueueAnim duration={2000} className="queue-simple" type="right">
											<h3 key="phone-title" className="margin-bottom-30">微信小程序</h3>
											<p key="phone-p">微信小程序即可远程登录监测平台访问云数据，实时掌握结构安全状态，通过移动设备实时查看告警信息，更新告警问题处理进度，让工作更方便，快捷高效。
											</p>
										</QueueAnim>
									</div>
								</div>
							</div>

						</QueueAnim>
					</OverPack>

				</div>
			</div>
		</div>
	)
}
