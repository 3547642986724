import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import QueueAnim from 'rc-queue-anim';
import { OverPack } from 'rc-scroll-anim';

export const Testimonials = (props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const data = [
    { thumb: "img/testimonials/01.jpg" },
    { thumb: "img/testimonials/03.png" },
    { thumb: "img/testimonials/05.jpg" },
    { thumb: "img/testimonials/04.jpg" },
    { thumb: "img/testimonials/06.png" },
    { thumb: "img/testimonials/07.png" },
    { thumb: "img/testimonials/10.png" },
    { thumb: "img/testimonials/08.png" },
    { thumb: "img/testimonials/09.png" }
  ];

  const images = data.map(obj => obj.thumb.replace("-small", "-large"));
  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (

    <div id='testimonials'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>解决方案</h2>
        </div>
        <div className='row'>
          <OverPack style={{ width: '100%' }} playScale={0.4}>
            <QueueAnim key="queue"
              leaveReverse

            >
              {props.data
                ? props.data.map((d, index) => (
                  <div key={d.name} onClick={() => openImageViewer(index)} className='col-md-6'>
                    <div className="portfolio-item cursor">
                      <div className="hover-bg">
                        <div className="hover-text">
                          <h3 >监测内容:</h3>
                          {
                            d.contect.map(function (item1, number) {
                              return (
                                <h4 key={number} id={number}>{number + 1}.{item1}</h4>
                              );
                            })
                          }

                        </div>
                        <div className='testimonial'>
                          <div className='testimonial-image'>
                            {' '}
                            <img src={d.img} alt='' />{' '}
                          </div>
                          <div className='testimonial-content'>
                            <div className='testimonial-meta'>{d.name} </div>
                            <p>{d.text}</p>

                          </div>
                        </div>
                      </div>
                    </div>



                  </div>
                ))
                : 'loading'}
            </QueueAnim>
          </OverPack>
        </div>
        {isViewerOpen && (
          <ImageViewer
            src={images}
            backgroundStyle={{ zIndex: 99999 }}
            currentIndex={currentImage}
            onClose={closeImageViewer}
          />
        )}
      </div>
    </div>
  )
}
