export const Footer = (props) => {
  
  return (
    <div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; Copyright © 2016-2021 zyiot.vip All Rights Reserved. ICP备案号: 豫ICP备2021021365号-1{' '}
           
          </p>
        </div>
      </div>
    </div>
  )
}
